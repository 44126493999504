
.action {
  display: flex;
  justify-content: flex-end;
}

.formBox {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.link {
  text-decoration: underline;
  font-size: 0.9em;
  cursor: pointer;
}
