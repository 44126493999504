@import './variablesNew.scss';

.action {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}
.formBox {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  & .field {
    flex-basis: calc(100% / 3 - 10px);
    @media screen and (max-width: $tablet-width) {
      flex-basis: 100%;
    }
    // @include media('<#{$tablet-step}') {
    //   flex-basis: 100%;
    // }
  }
  & .phone, .city {
    flex-basis: calc(50% - 10px);
    // @include media('<#{$tablet-step}') {
    //   flex-basis: 100%;
    // }
  }
}