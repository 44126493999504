@import 'styles/variables';

.emailCheck, .login, .restorePass {
  max-width: 290px;
}

.registration {
  max-width: 600px;
}

.action {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}
